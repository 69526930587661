const lang = {
  "nav.inicio": "Home",
  "nav.oradores": "Speakers",
  "nav.socios": "Our Partners",
  "nav.socios.sponsors": "Sponsors",
  "nav.socios.colaboradores": "Collaborators",
  "nav.organizacion": "Organization",
  "nav.prensa": "News",
  "nav.fotos.y.videos": "Gallery",
  "nav.acerca.de": "About us",
  "nav.contacto": "Contact us",
  "nav.contacto.como.llegar": "Location",
  "nav.contacto.contactanos": "Contact us",
  "nav.ted": "What is TED?",
  "nav.tedx": "What is TEDx?",
  "contact.title": "Contact",
  "contact.form.name": "Name",
  "contact.form.phone": "Phone",
  "contact.form.email": "Email",
  "contact.form.message": "Mensaje",
  "contact.form.send": "Send",
  "footer.copyright": "@" + new Date().getFullYear() + " TEDxVillaLaAngostura. TEDx event is organized under license from TED.",
};

export default lang;
