export const LOCALE_SET = "LOCALE_SET";
export const SEND_FORM = "SEND_FORM";

export const SHOW_ERRORS = "SHOW_ERRORS";
export const IS_LOADING = "IS_LOADING";
export const SET_MESSAGE = "SET_MESSAGE";
export const UPDATE_FORM = "UPDATE_FORM";

export const MODAL_SPEAKER_SET = "MODAL_SPEAKER_SET";
export const SPEAKER_SET = "SPEAKER_SET";
